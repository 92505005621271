<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>公文管理</el-breadcrumb-item>
      <el-breadcrumb-item>活动列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <!-- 商品列表 -->
      <el-table ref="multipleTable" :data="commodityList" tooltip-effect="dark" style="width: 100%">
        <el-table-column type="selection" width="55">
        </el-table-column>
        <el-table-column label="活动名称" prop="groupName">
        </el-table-column>
        <el-table-column label="活动图片" prop="groupPic">
          <template slot-scope="scope">
            <img :src="scope.row.groupPic" style="height:70px;width:70px;border-radius:50%" />
          </template>
        </el-table-column>
        <el-table-column prop="groupText" label="活动描述">
        </el-table-column>
        <el-table-column prop="groupCount" label="团购人数">
        </el-table-column>
        <el-table-column prop="originalPrice" label="团购原价">
        </el-table-column>
        <el-table-column prop="currentYield" label="团购现价">
        </el-table-column>
        <el-table-column label="门店价格">
          <template slot-scope="scope">
            <div>
              {{scope.row.menDianPrice || scope.row.currentYield}}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div style="display: flex;">
              <el-button :type="scope.row.state?'warning':'primary'" size="mini" @click="watchDetail(scope.row)">
                {{scope.row.state?'退出':'参与'}}</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination style="margin: 15px 0" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="page" :page-sizes="[5,10,20]" :page-size="limit" layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        commodityList: [],
        page: 1,
        limit: 5,// 每页显示
        total: 0,//总条数
      }
    },
    created() {
      this.getList()
    },
    methods: {
      // 参与活动
      watchDetail(item) {
        let url = `/relevance?proId=${item.id}`
        let obj
        let msg = '收款价格将以门店价格为准，是否参与?'
        if (item.state) {
          // 退出活动
          url = `/update`
          obj = {
            state: '0',
            id: item.id
          }
          msg = '确认退出该活动，是否继续?'
        }
        this.$confirm(msg, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.post(url, obj).then(res => {
            console.log(res)
            if (res.data.code == 200) {
              this.$message.success(res.data.msg)
              this.getList()
            }
          })
        })
      },
      // 获取商品列表
      getList() {
        this.$http.post(`/groupList`).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            this.commodityList = res.data.data
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },
      // 参加活动
      handleEdit(index, row) {
        this.centerDialogVisible = true
        this.currentDialog = '商品核销'
        this.input3 = row.proUnique
        this.searchInput()
      },
      //每页大小改变
      handleSizeChange(newSize) {
        this.limit = newSize
        this.getList()
      },
      // 当前页改变
      handleCurrentChange(newPage) {
        this.page = newPage
        this.getList()
      },

    }
  }
</script>

<style scoped lang="less">
  .dialogItem {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .title {
      width: 100px;
    }
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>